import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Im_paiment from '../../assets/img/icons/paiment.svg';
import OfferPeriod from '../../pages/Offers/OfferPeriod';
import OfferOoredooPeriods from '../../pages/Offers/OfferOoredooPeriods';
import { addDiscountCode } from '../../redux/slices/points';
import CouponItem from './couponItem';
import { formatMessages } from '../../utilities/translateHelper';
import OoredooItem from './OoredooModals/OoredooItem';
import PaimentOoreedoo from './OoredooModals/PaimentOoreedoo';
import { digitalvirgoRedirect } from '../../redux/slices/offers';
import { addGiftCode, getGeneratedGift } from '../../redux/slices/parrain';
import amountAfterGiftCode from '../../utilities/amountAfterGiftCode';

const Index = (props) => {
  const { i18n, t } = useTranslation();
  const {
    offer,
    open,
    setOpen,
    handleAcheter,
    prix,
    amount,
    offerId,
    weekly,
    daily,
    offerDivisionId,
    typePeriod,
    offerParam,
    color,
    width,
    setPeriod,
    selectedPeriod,
    setPricingIndex,
    discountType,
    discountValueNoCoupon,
    priceNoCoupon,
    finalPriceNoCoupon,
    indexSliceToPay,
    isFree,
    setSelectedLabel,
    offerLength,
    basePrice,
    getPrice,
    pricingIndex,
  } = props;
  const {
    isDiscountCodesLoading,
    discount,
    discountError,
    discountCodes,
    isAddingDiscountLoading,
    deletedDiscountCode,
  } = useSelector((state) => state.points);
  const { generatedGifts, generatedGiftsStatus, addGiftCodeSuccess, addGiftCodeError } =
    useSelector((state) => state.parrain);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [discountCode, setDiscountCode] = useState({ type: null, value: null });
  const [data, setData] = useState([]);
  const [openPaiment, setOpenPaiment] = useState(false);
  const [ooredooPeriodValue, setOoredooPeriodValue] = useState('1 jour');
  const [selectedOoredooPeriod, setOoredooPeriod] = useState(ooredooPeriodValue);
  const [paimentData, setPaimentData] = useState({ recurrence: '', offerDivisionDetail: '' });
  const [ooreedooPrice, setOoreedooPrice] = useState();
  const { digitalVirgoClient } = useSelector((state) => state.user);
  const quantityCheck =
    data?.length > 0 &&
    data?.filter((p) =>
      selectedPeriod !== "reste de l'année"
        ? p?.discount_code_offer_division?.quantity?.includes(selectedPeriod)
        : p.discount_code_offer_division?.all_year === true
    );

  const finalCodes =
    quantityCheck?.length > 0
      ? quantityCheck?.filter((item) => {
          return item?.discount_code_offer_division?.discount_code_offers?.length > 0
            ? item?.discount_code_offer_division?.discount_code_offers?.find(
                (p) => p.offer.id === offerId
              )
            : item;
        })
      : [];
  // set default values for period = 6
  const offerPeriods =
    offerParam?.pricing &&
    offerParam?.pricing?.filter((item) => item.period !== "reste de l'année");
  const currentPeriod = offerPeriods && offerPeriods[pricingIndex];
  const defaultDiscountValue = currentPeriod?.infoDiscount?.discountValue;
  const defaultDiscountType = currentPeriod?.infoDiscount?.discountType;
  const handleDaily = () => {
    setOpenPaiment(true);
    setPaimentData({ recurrence: '1', offerDivisionDetail: offerDivisionId });
    setOoreedooPrice(daily);
  };
  const handleWeekly = () => {
    setOpenPaiment(true);
    setPaimentData({ recurrence: '7', offerDivisionDetail: offerDivisionId });
    setOoreedooPrice(weekly);
  };
  useEffect(() => {
    if (!isDiscountCodesLoading) {
      setData(discountCodes);
    }
    if (deletedDiscountCode && data?.length === 1) {
      setData(null);
    }
  }, [discountCodes]);

  const handleClose = () => {
    setOpen(false);
    setOpenPaiment(false);
    setPaimentData({ recurrence: '', offerDivisionDetail: '' });
    setOoreedooPrice();
    //setPricingIndex(lastIndex);
    //setPeriod("reste de l'année")
    //setSelectedLabel("reste de l'année");
  };

  useEffect(() => {
    if (generatedGiftsStatus !== 'succeeded' && generatedGiftsStatus !== 'failed') {
      dispatch(getGeneratedGift());
    }
  }, [generatedGiftsStatus]);

  const handleSubmit = () => {
    if (discountCode.value == null) {
      setError(t('Code coupon requis'));
      setTimeout(() => {
        setError(null);
        setSuccess(null);
      }, 3000);
    } else if (indexSliceToPay !== 1 && indexSliceToPay !== -1) {
      if (discountCode.type === 'parrain') {
        setError(t('Vous pouvez ajouter un code parrain que pour la premiére tranche'));
      } else {
        setError(t('Vous pouvez ajouter un code coupon que pour la premiére tranche'));
      }
    } else if (discountCode.type === 'parrain') {
      dispatch(addGiftCode(discountCode.value));
      setOpen(true);
      setError(null);
      setTimeout(() => {
        setError(null);
        setSuccess(null);
      }, 3000);
    } else {
      dispatch(
        addDiscountCode(
          discountCode.value,
          offerId,
          selectedPeriod,
          selectedPeriod === "reste de l'année" ? 1 : 0
        )
      );
      setOpen(true);
      setTimeout(() => {
        setError(null);
        setSuccess(null);
      }, 3000);
    }
  };
  const handleRedirect = () => {
    dispatch(digitalvirgoRedirect(paimentData.recurrence));
  };

  useEffect(() => {
    if (discountCode.type === 'parrain') {
      setSuccess(addGiftCodeSuccess);
    } else {
      setSuccess(discount);
    }
  }, [discountCode, addGiftCodeSuccess, discount]);

  useEffect(() => {
    if (discountCode.type === 'parrain') {
      setError(addGiftCodeError);
    } else {
      setError(discountError);
    }
  }, [discountCode, addGiftCodeError, discountError]);
  const showTextField =
    generatedGiftsStatus !== 'loading' && generatedGifts.length === 0 && finalCodes.length === 0;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      titleStyle={{ textAlign: 'center' }}
      aria-describedby="alert-dialog-description"
      className="couponModal"
      fullWidth={true}
      maxWidth="lg"
    >
      <div className="modal-close-coupon" onClick={handleClose}>
        <IconButton onClick={handleClose}>
          <CloseIcon className="close-coupon" />
        </IconButton>
      </div>
      {typePeriod === 'Par jour' && (
        <DialogContent className={'content'}>
          {(weekly || daily) && !openPaiment ? (
            <div>
              <div
                style={{ display: 'flex', marginTop: '50px', justifyContent: 'space-between' }}
              />
              <div className="ooreedoo-periods">
                <OfferOoredooPeriods
                  classCard={'down'}
                  setOoredooPeriod={setOoredooPeriod}
                  ooredooPeriodValue={selectedOoredooPeriod}
                  color={offerParam.color}
                  selectedOoredooPeriod={selectedOoredooPeriod}
                  width={width}
                />
              </div>
              <div className="Content-ooredoo">
                {selectedOoredooPeriod === '1 jour' && (
                  <OoredooItem
                    //   onClick={handleDaily}
                    color={color}
                    text={
                      <span>
                        {t('Ce type d abonnement est renouvelable automatiquement chaque jour')}
                        <br /> <br /> {t('Vous pouvez annuler votre abonnement a tout moment')}
                      </span>
                    }
                    price={`${daily}  ${t('TND')} / ${t('jour')}`}
                  />
                )}
                {selectedOoredooPeriod === '7 jours' && (
                  <OoredooItem
                    //  onClick={handleWeekly}
                    color={color}
                    text={
                      <span>
                        {t("Ce type d'abonnement est renouvelable automatiquement chaque semaine")}
                        <br /> <br /> {t('Vous pouvez annuler votre abonnement a tout moment')}
                      </span>
                    }
                    price={`${weekly}  ${t('TND')} / ${t('Semaine')}`}
                  />
                )}
              </div>
              <div className="group-btn">
                <Button
                  onClick={
                    selectedOoredooPeriod === '1 jour'
                      ? handleDaily
                      : selectedOoredooPeriod === '7 jours'
                      ? handleWeekly
                      : handleAcheter
                  }
                  className="acheter"
                  style={{ backgroundColor: color, marginBottom: '30px', width: '80%' }}
                >
                  {isFree ? t('Essayer gratuitement') : t('Acheter')}
                </Button>
              </div>
            </div>
          ) : (
            <div style={{ width: '100%' }}>
              {!openPaiment && (
                <div>
                  <div className={'offer-Title'} style={{ color: color }}>
                    {offer}
                  </div>
                  <div className="simple-price-offer">
                    <div
                      style={{ marginBottom: '40px' }}
                      className={i18n.language === 'ar' ? 'price-header-rtl' : ''}
                    >
                      <span className="total">{t('Total')} </span>

                      {finalCodes?.length > 0 && !isDiscountCodesLoading && (
                        <div className="discount-price">
                          <span>
                            {amountAfterGiftCode(
                              amount(finalCodes, prix, basePrice, indexSliceToPay),
                              generatedGifts?.length,
                              0.05
                            )}{' '}
                            {t('TND')}
                          </span>
                        </div>
                      )}
                      {generatedGifts?.length > 0 && finalCodes?.length === 0 && (
                        <div className="discount-price">
                          <span>
                            {amountAfterGiftCode(prix, generatedGifts?.length, 0.05)} {t('TND')}
                          </span>
                        </div>
                      )}
                      <div
                        className={
                          (finalCodes?.length === 0 && generatedGifts?.length === 0) ||
                          isDiscountCodesLoading
                            ? 'before-discount'
                            : 'after-discount'
                        }
                      >
                        {isFree === true ? t('Gratuit') : prix + ' ' + t('TND')}
                        {'   '}
                        {finalCodes?.length > 0 &&
                          !isDiscountCodesLoading &&
                          !(
                            finalCodes[0]?.discount_code_offer_division.type === 'discount' ||
                            finalCodes[1]?.discount_code_offer_division.type === 'discount'
                          ) && (
                            <span className="discount-value">
                              -
                              {Math.round(
                                (1 -
                                  amount(finalCodes, prix, basePrice, indexSliceToPay) /
                                    getPrice(finalCodes, prix, basePrice, indexSliceToPay)) *
                                  100
                              )}{' '}
                              %
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {openPaiment && (
            <PaimentOoreedoo
              image={Im_paiment}
              buttonText={t('Procéder au paiement')}
              disabledBtn={digitalVirgoClient === true}
              buttonWidth={'auto'}
              onClick={handleRedirect}
              color={color}
              text={
                <span>
                  {t(
                    'En cliquant sur ce bouton, vous serez redirigé vers une interface de paiement sécurisée'
                  )}
                  <br /> <br />
                  {` ${t('Prix')} ${ooreedooPrice} ${t('TND')}`}{' '}
                </span>
              }
            />
          )}
        </DialogContent>
      )}
      {!isFree && typePeriod === 'Par mois' && (
        <DialogContent className={'content'}>
          <div
            style={{
              direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
              display: 'flex',
              marginTop: '30px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '-20px' }}>
              <span className="total">{t('Total')} </span>
              <div className="offer-price-in-coupon">
                <Typography
                  style={{
                    paddingLeft: finalPriceNoCoupon ? '30px' : '0px',
                  }}
                >
                  <span
                    className="base-offer-price-in-coupon"
                    style={{
                      textDecoration: 'line-through',
                      display:
                        (finalCodes?.length > 0 && !isDiscountCodesLoading) ||
                        priceNoCoupon === prix ||
                        offerParam.type === 'pack'
                          ? 'none'
                          : 'inline',
                    }}
                  >
                    {priceNoCoupon}
                    {discountValueNoCoupon ? '' : t('Dt')}
                  </span>
                  {defaultDiscountValue && (
                    <span
                      id="Remise"
                      style={{
                        display:
                          finalCodes?.length > 0 && !isDiscountCodesLoading ? 'none' : 'inline',
                      }}
                    >
                      -
                      {defaultDiscountType === 'percentage_discount'
                        ? defaultDiscountValue + '%'
                        : defaultDiscountValue + t('Dt')}
                    </span>
                  )}
                </Typography>
              </div>
              <div className={i18n.language === 'ar' ? 'price-header-rtl' : 'price-header'}>
                {finalCodes?.length > 0 && !isDiscountCodesLoading && (
                  <div className="discount-price">
                    <span>
                      {amountAfterGiftCode(
                        amount(finalCodes, prix, basePrice, indexSliceToPay),
                        generatedGifts?.length,
                        0.05
                      )}{' '}
                      {t('TND')}
                    </span>
                  </div>
                )}
                {generatedGifts?.length > 0 && finalCodes?.length === 0 && (
                  <div className="discount-price">
                    <span>
                      {amountAfterGiftCode(prix, generatedGifts?.length, 0.05)} {t('TND')}
                    </span>
                  </div>
                )}
                <div
                  className={
                    (finalCodes?.length === 0 && generatedGifts?.length === 0) ||
                    isDiscountCodesLoading
                      ? 'before-discount'
                      : 'after-discount'
                  }
                >
                  {prix} {t('Dt')}
                </div>
                {finalCodes?.length > 0 &&
                  !isDiscountCodesLoading &&
                  !(
                    finalCodes[0]?.discount_code_offer_division.type === 'discount' ||
                    finalCodes[1]?.discount_code_offer_division.type === 'discount'
                  ) && (
                    <div className="discount-value">
                      -
                      {Math.round(
                        (1 -
                          amount(finalCodes, prix, basePrice, indexSliceToPay) /
                            getPrice(finalCodes, prix, basePrice, indexSliceToPay)) *
                          100
                      )}{' '}
                      %
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div></div>
          {offerParam.type === 'primary' && (
            <div className="offerPeriodDialog">
              <OfferPeriod
                classCard={'down'}
                color={offerParam.color}
                // pricings={offerParam.pricing}
                pricings={offerPeriods}
                setPeriod={setPeriod}
                selectedPeriod={selectedPeriod ? selectedPeriod : currentPeriod?.period}
                selectedLabel={typePeriod === 'Par mois' ? currentPeriod?.period : selectedPeriod}
                setSelectedLabel={setSelectedLabel}
                setPricingIndex={setPricingIndex}
                width={width}
                offerLength={offerLength}
              />
            </div>
          )}
          <div className="group-btn">
            <Button onClick={handleAcheter} className="acheter" style={{ backgroundColor: color }}>
              {isFree ? t('Essayer gratuitement') : t('Acheter')}
            </Button>
          </div>
          <br />

          {finalCodes !== [] &&
            finalCodes?.map((item) => {
              return (
                <div className="code-list-item">
                  <CouponItem
                    couponCode={item?.discount_code_offer_division?.discount_code}
                    offerId={offerId}
                    id={item.id}
                  />
                </div>
              );
            })}
          {generatedGifts?.map((item) => {
            return (
              <div className="code-list-item">
                <CouponItem couponCode={item?.gift_code} id={item?.id} parrain />
              </div>
            );
          })}
          {showTextField && (
            <>
              <div className={i18n.language === 'ar' ? 'message-code-rtl' : 'message-code'}>
                {t("Ajouter votre code coupon pour bénéficier d'une réduction")}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <TextField
                  disabled={finalCodes?.length >= 1}
                  outlined
                  id={`code success`}
                  label={t('Taper votre code coupon')}
                  type="string"
                  value={discountCode.value}
                  margin="dense"
                  variant="outlined"
                  className={`code-promo`}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSubmit}
                          className="validate"
                          style={{ backgroundColor: finalCodes?.length >= 1 ? 'lightgray' : color }}
                          disabled={isAddingDiscountLoading || finalCodes?.length >= 1}
                          edge="end"
                          root={{ borderRadius: '0.1em' }}
                        >
                          {isAddingDiscountLoading ? (
                            <CircularProgress style={{ color: 'white' }} />
                          ) : (
                            t('Appliquer')
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    setDiscountCode({
                      type:
                        e.target.value.toUpperCase()?.trim()?.split('-')?.[0] === 'CP'
                          ? 'parrain'
                          : 'coupon',
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </>
          )}
          <div className="message-group">
            {(success || error) && (
              <span className={success ? 'message-discount-success' : 'message-discount-warning'}>
                {t(formatMessages(success)) || t(formatMessages(error))}
              </span>
            )}
          </div>
        </DialogContent>
      )}
      {(typePeriod === "reste de l'année" || typePeriod === '1' || isFree) && (
        <DialogContent className={'content'}>
          <div style={{ display: 'flex', marginTop: '30px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className={i18n.language === 'ar' ? 'price-header-rtl' : 'price-header'}>
                <span className="total">{t('Total')} </span>
                {finalCodes?.length > 0 && !isDiscountCodesLoading && (
                  <div className="discount-price">
                    <span>
                      {amountAfterGiftCode(
                        amount(finalCodes, prix, basePrice, indexSliceToPay),
                        generatedGifts?.length,
                        0.05
                      )}{' '}
                      {t('TND')}
                    </span>
                  </div>
                )}
                {generatedGifts?.length > 0 && finalCodes?.length === 0 && (
                  <div className="discount-price">
                    <span>
                      {amountAfterGiftCode(prix, generatedGifts?.length, 0.05)} {t('TND')}
                    </span>
                  </div>
                )}

                <div
                  className={
                    (finalCodes?.length === 0 && generatedGifts?.length === 0) ||
                    isDiscountCodesLoading
                      ? 'before-discount'
                      : 'after-discount'
                  }
                >
                  {isFree === true ? t('Gratuit') : prix + ' ' + t('TND')}
                </div>
                {finalCodes?.length > 0 &&
                  !isDiscountCodesLoading &&
                  !(
                    finalCodes[0]?.discount_code_offer_division.type === 'discount' ||
                    finalCodes[1]?.discount_code_offer_division.type === 'discount'
                  ) && (
                    <div className="discount-value">
                      -
                      {Math.round(
                        (1 -
                          amount(finalCodes, prix, basePrice, indexSliceToPay) /
                            getPrice(finalCodes, prix, basePrice, indexSliceToPay)) *
                          100
                      )}{' '}
                      %
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="group-btn">
            <Button onClick={handleAcheter} className="acheter" style={{ backgroundColor: color }}>
              {isFree ? t('Essayer gratuitement') : t('Acheter')}
            </Button>
          </div>
          <br />
          {finalCodes !== [] &&
            finalCodes?.map((item) => {
              return (
                <div className="code-list-item">
                  <CouponItem
                    couponCode={item?.discount_code_offer_division?.discount_code}
                    offerId={offerId}
                    id={item.id}
                  />
                </div>
              );
            })}
          {generatedGifts?.map((item) => {
            return (
              <div className="code-list-item">
                <CouponItem couponCode={item?.gift_code} id={item?.id} parrain />
              </div>
            );
          })}

          {showTextField && (
            <>
              <div className={i18n.language === 'ar' ? 'message-code-rtl' : 'message-code'}>
                {t("Ajouter votre code coupon pour bénéficier d'une réduction")}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <TextField
                  disabled={finalCodes?.length >= 1}
                  outlined
                  id={`code success`}
                  label={t('Taper votre code coupon')}
                  type="string"
                  value={discountCode.value}
                  margin="dense"
                  variant="outlined"
                  className={`code-promo`}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSubmit}
                          className="validate"
                          style={{ backgroundColor: finalCodes?.length >= 1 ? 'lightgray' : color }}
                          disabled={isAddingDiscountLoading || finalCodes?.length >= 1}
                          edge="end"
                        >
                          {isAddingDiscountLoading ? (
                            <CircularProgress style={{ color: 'white' }} />
                          ) : (
                            t('Appliquer')
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    setDiscountCode({
                      type:
                        e.target.value.toUpperCase()?.trim()?.split('-')?.[0] === 'CP'
                          ? 'parrain'
                          : 'coupon',
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </>
          )}
          <div className="message-group">
            {(success || error) && (
              <span className={success ? 'message-discount-success' : 'message-discount-warning'}>
                {t(formatMessages(success)) || t(formatMessages(error))}
              </span>
            )}
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

Index.propTypes = {
  onCloseModal: PropTypes.func,
};

export default Index;
